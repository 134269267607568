import React, { FC, useState, useEffect, ReactNode } from "react"
import { useJSONState, useTranslate } from "../hooks";
import { Switch, TextField, Form, Container, Grid, Button } from "../components";

type ObjKeyProps<T extends "text" | "number" | "switch"> = {
  name: string;
  type: T;
  required?: boolean;
  readOnly?: boolean;
  value?: T extends "text" ? string : T extends "number" ? number : T extends "switch" ? boolean : never;
};

type ObjType = {
  [key: string]: ObjKeyProps<"text" | "number" | "switch">;
};

type HeadOptionsType = {
  title?: string,
  okText?: string,
  cancelText?: string,
  hideCancelButton?: boolean,
  validation?: () => Promise<void>
}

type ResultType<T extends Record<string, ObjKeyProps<any>>> = {
  [K in keyof T]: T[K]["type"] extends "switch" ? boolean :
                 T[K]["type"] extends "number" ? number :
                 T[K]["type"] extends "text" ? string :
                 never;
};

type DialogProps = {
  children?: ReactNode;
  mainDialog?: boolean;
  obj?: ObjType;
};

let dialogCallback: (obj?: ObjType, headOptions?: HeadOptionsType) => void;
let resolvePromise: (result: any | null) => void;

const dialog = {
  open: async <T extends ObjType>(obj: T, headOptions?: HeadOptionsType): Promise<ResultType<T> | null> => {
    return new Promise<ResultType<T> | null>((resolve) => {
      if (dialogCallback) dialogCallback(obj, headOptions);
      resolvePromise = resolve; // Directly assign resolve
    });
  },
  close: () => dialogCallback && dialogCallback(undefined),
  _register: (callback: (obj?: ObjType, headOptions?: HeadOptionsType) => void) => {
    dialogCallback = callback;
  },
};

const Dialog: FC<DialogProps> = ({ mainDialog, children }) => {
  const [dialogState, setDialogState] = useState<{ obj?: ObjType, headOptions?: HeadOptionsType}>({obj: undefined, headOptions:undefined});
  const [ json, setProp, setJson ] = useJSONState<ResultType<ObjType>>({})
  const [ errorMessage, setErrorMessage ] = useState("")
  const { t } = useTranslate()

  useEffect(() => {
    if(mainDialog) dialog._register((obj?: ObjType, headOptions?: HeadOptionsType) => {
      setErrorMessage("")
      setDialogState({ obj, headOptions })
      let json = {} as any
      obj && Object.keys(obj).map(key => {
        let r = obj[key].value ?? (obj[key].type === "switch" ? false : undefined)
        json[key] = r
      })
      setJson(json)
    });
  }, []);

  useEffect(() => {
    const dialogElement = document.querySelector(".schipt-dialog") as HTMLDialogElement;
  
    if (!!children || !!dialogState.obj) {
      dialogElement?.showModal(); // Ensures the dialog behaves as a modal
    } else {
      dialogElement?.close();
    }
  }, [children, dialogState.obj]);


  return (
    <dialog className="schipt-dialog">
      {
        dialogState.obj ? 
        <Container>
        <Form onSubmit={async () => {                
          try{
            if(dialogState.headOptions?.validation) await dialogState.headOptions.validation()

            resolvePromise(json)
            dialog.close()
            return Promise.resolve()
          }catch(e){
            setErrorMessage(e)
            return Promise.resolve()
          }                    
        }}>
          {!dialogState.headOptions?.title ? <></> : <h2>{dialogState.headOptions.title}</h2>}
          <>
            {Object.keys(dialogState.obj).map((key, i) => {
              if(!dialogState?.obj?.[key]) return <></>
              let o = dialogState.obj[key]

              if(o.type === "switch") return <Switch 
                label={o.name}
                checked={json[key]}
                onChange={() => {
                  setProp(!json[key], key)
                  setErrorMessage("")
                }}
                key={i}
              />

              return <TextField 
                required={o.required} 
                _type={o.type}
                readOnly={o.readOnly}
                value={json[key]}
                onChange={(e) => {
                  setProp(e.currentTarget.value, key)
                  setErrorMessage("")
                }}
                key={i}
              >{o.name}</TextField>
            })
            }
          </>
            <Grid>
              <Button _type="submit" variant="submit">{dialogState.headOptions?.okText ?? t("ok")}</Button>
              <Button _type="button" variant="undo" onClick={() => {
                resolvePromise(null)
                dialog.close()
              }}>{dialogState.headOptions?.cancelText ?? t("close")}</Button>
            </Grid>
            {errorMessage === "" ? <></> :
              <div style={{color: "red"}}>{errorMessage}</div>
            }
        </Form>
        </Container>
        :
        children
      } 
    </dialog>
  );
};


export default Dialog

export { dialog }