import React, { FC, useMemo, useState } from 'react'
import "../../assets/style/TextField.css"
import { v4 as uuidv4 } from 'uuid';

type Props/*<T extends 'text' | 'email' | 'search' | 'password' | 'date' | 'number' | 'month' | 'week' | 'time' | 'file' | 'tel'>*/ = {
  children?: string,
  _type?: 'text' | 'email' | 'search' | 'password' | 'date' | 'number' | 'month' | 'week' | 'time' | 'file' | 'tel' //T,
  _ref?: React.MutableRefObject<null>,
  adornment?: string,
  required?: boolean,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  autoFocus?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  value?: string | number | null//T extends "number" ? number : string;
  readOnly?: boolean,
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
  title?: string,
  style?: any,
  error?: string,
  className?: string,
  decimals?: number,
  max?: number,
  min?: number
  [key: string]: any
}

/**
 * 
 * @param children text to be displayed
 * @param _type text | email | search | password | date | number | month | week | time | file | tel
 * @param {string} _ref a ReaMutableRefObject
 * @param {string} adornment unit to be displayed. Examples: kg, km
 * @param {boolean} required 
 * @param {function} onKeyDown 
 * @param {boolean} autoFocus 
 * @param {function} onChange 
 * @param {string} value 
 * @param {string} readOnly 
 * @param {string} onBlur
 * @param {string} title hovertext
 * @returns 
 */
const TextField: FC<Props> = ({ children, _type, _ref, adornment, required, onKeyDown, autoFocus
  , onChange, value, readOnly, onBlur, title, error, className, decimals, min, max, ...props }) => {
  const id = useMemo(() => uuidv4(), [])

  if(_type === "number" && value){
    let decs = ""
    // we work with whole numbers. replace all dots
    if (!decimals || decimals < 1) {
      value = value.toString().replace(/[^0-9]/g, '');
    } else {
      value = value.toString().replaceAll(",", '.');
      value = value.replace(/[^0-9.]/g, '');
      let firstDotApp = value.indexOf(".")
      // Remove trailing dots. only 1 dot allowed.
      if (firstDotApp > 0) {
        let wholeNumAndDot = value.substring(0, firstDotApp + 1)
        decs = value.substring(firstDotApp + 1).replaceAll(".", "")

        value = wholeNumAndDot + decs;
      } else if (firstDotApp === 0) { // if dot is the first letter we add a 0 before it
        value = "0" + value
        firstDotApp = 1
      }

      if (isNaN(parseFloat(value))) value = ""
      else {
        // dot is the last character, do not remove..
        if (firstDotApp < value.length - 1)
          value = parseFloat(parseFloat(value).toFixed(Math.min(decimals, decs.length))).toString();
      }
    }
    if (max && value && parseFloat(value.toString()) > max) value = max
  }


  return (<>
    <div className='relative w-full md:w-auto'>
      <input type={_type === "number" ? "text" : (_type ?? "text")}
        inputMode={_type === "number" && decimals && decimals > 0 ? "decimal" :
          _type === "number" ? "numeric" : undefined
        }
        id={id}
        placeholder={children}
        ref={_ref}
        required={required ?? false}
        autoFocus={autoFocus ?? false}
        onKeyDown={(e) => onKeyDown?.(e)}
        onChange={(e) => onChange?.(e)}
        value={value || ''}
        readOnly={readOnly}
        onBlur={(e) => onBlur?.(e)}
        title={title}
        {...props}
        className={`w-full py-1 pl-1 border-schipt-dark-gray read-only:opacity-50 read-only:pointer-events-none border-solid border text-sm font-montserrat rounded-sm bg-white dark:bg-schipt-dark focus:outline-none placeholder-transparent  focus:shadow-3xl focus:shadow-schipt-dark-gray transition-colors peer ` +
          (_type === "email" ? "w-80 " : " ") +
          (className ?? "")
        }
      />
      <label htmlFor={id} className="absolute left-0 -top-4  text-schipt-dark-gray dark:text-schipt-white-90 text-xs font-semibold font-montserrat peer-focus:-top-4 peer-focus:font-md peer-focus:text-md peer-focus:left-0 peer-focus:pl-0 transition-all peer-placeholder-shown:text-schipt-dark-gray dark:peer-placeholder-shown:text-schipt-white-60 peer-placeholder-shown:text-opacity-50 peer-placeholder-shown:pl-1 peer-placeholder-shown:top-2 pointer-events-none">
        {children}
      </label>
      {adornment &&
        <p className='adornment'>{adornment}</p>
      }
      {error ? <><br /><p className='absolute -top-5 -right-0 text-xs text-rose-500 w-100 text-nowrap'>{error}</p></> : <></>}
    </div>
  </>
  )
}

export default TextField;