import React, { FC } from "react"
import { Switch, Select } from "../Inputs"
import { useTranslate } from "../../hooks"

import "../../assets/style/InputTable.css"
import { ZodError } from "zod"
import { FaCirclePlus, FaXmark } from "react-icons/fa6"

type HeaderKeyProps = {
  type: 'text' | 'number' | 'checkbox' | 'select',
  adornment?: string,
  required?: boolean,
  readOnly?: boolean,
  min?: number,
  max?:number,
  maxLength?: number,
  decimals?: number,
  error?: string,
  options?: {
    name: string,
    value: string | number
  }[]
}

type HeaderProps = {
  [key: string]: HeaderKeyProps// | 'text' | 'number' | 'checkbox'
}

type DataProps = {
  [key: string]: string | number | boolean | undefined
}

type Props = {
  data: DataProps[] | undefined,
  headers: HeaderProps,
  addable?: boolean | number,
  deleteable?: boolean | number,
  setProp?: (value: any, ...properties: (string | number)[]) => void,
  propPath?: string,
  zodErrors?: ZodError<any>
}

/**
 * The accordion component allows the user to show and hide sections of related content on a page
 * @param data An array with objects. Each row should contain the same keys
 * @param headers An object descriping the input types of each column.
 * @param addable Wether rows are addable. If a number is supplied (example 2), then only two rows can be added.
 * @param deleteable Wether rows are deleteable. If a number is supplied (example 3), then the first three rows cannot be deleted.
 * @param setProp a useJSONState `setProp` to alter the data.
 * @param propPath A root path for the altered data, leading up to the array that's being send in `data`
 * @param zodErrors A Zod-error type for validating the data. Example: zodErrors={validationErrors as ZodError<ShipmentType>}
 * @author Noah Sörman
 */
const InputTable: FC<Props> = ({ data, headers, addable, deleteable, setProp, propPath, zodErrors }) => {
  const { t } = useTranslate()
  
  const isAddable = () => {
    if(data == undefined || !addable) return <></>
    
    if(typeof addable === "boolean" || addable > data.length){
      return <div className="func flex justify-center">
        <div onClick={() => {
        //setProp && setProp(deepCopyOf(data[data.length - 1]), propPath!, data.length)
        setProp && setProp({}, propPath!, data.length)
      }}
          className="func">
          <p><FaCirclePlus className='text-schipt-green hover:text-schipt-dark-green mt-4 text-xl cursor-pointer ' /></p>
        </div>
      </div>
    }
    return <></>
  }

  const isDeleteable = (i?:number) => {    
    if(!deleteable) 
      return <></>
    
    if(i === undefined) 
      return <th></th>

    if (typeof deleteable === "boolean" || i + 1 > deleteable) 
      return <td key={i + '_deletable'} onClick={() => {
        setProp && setProp(null, propPath!, i, -1)
      }} className={`func del i${i}`}><p><FaXmark className='text-red-400 hover:text-red-600 text-xs' /></p></td>

    return <td></td>
  }

  return <>{data &&
    <div>
    <table className="schipt-input-table">
      <thead>
        <tr>
          {isDeleteable()}
          {
            Object.keys(headers).map(k => {
              let required = false
              if (typeof headers[k] === "object") required = headers[k]?.required ?? false
              return <th className={required ? "req" : ""} key={k}>{t(k)}</th>
            })
          }
        </tr>
      </thead>
      <tbody>
        {data.length > 0 &&
          data.map((dataRow, i) => {

            return <tr key={i}>
              {isDeleteable(i)}
              {
                Object.keys(headers).map(k => {
                  let headerType: string | HeaderKeyProps = headers[k]
                  let inputMode: "text" | "decimal" | "numeric" = "text"
                  let inputType = (typeof headerType === "string" ? headerType : "string")

                  let { min, max, decimals, maxLength } = headers[k]

                  let required = false;
                  let error = headers[k]?.error || zodErrors?.errors.find(e => e.path.join('.') === `${propPath}.${i}.${k}`)?.message
                  if (typeof headerType === "object") {
                    required = headers[k]?.required ?? false;
                    headerType = headers[k].type;
                  }

                  if (headerType === "checkbox") {
                    return (
                      <td
                        key={i + '_' + k}
                        className={ error ? "tooltip" : ""}
                        title=''
                      >
                        <Switch
                          
                          checked={dataRow[k] ? true : false}
                          onChange={() => setProp?.(!dataRow[k], propPath!, i, k)} key={i + '_' + k}
                        />
                        <span className="tooltiptext">{error}</span>
                      </td>
                    )
                  }

                  if (typeof headers[k]?.options !== "undefined") { //Select
                    return (
                      <td 
                        key={i + '_' + k}
                        className={ error ? "tooltip" : ""}
                      >
                        <Select 
                          data={headers[k].options ?? []}
                          value={dataRow[k] as string ?? ""}
                          onChange={(e) => setProp?.(e.currentTarget.value, propPath!, i, k)}
                        />
                        {/* <select
                          className={ error ? "error" : ""}
                          title=''
                          value={dataRow[k] as string}
                          onChange={(e) => setProp?.(e.target.value || undefined, propPath!, i, k)}
                          disabled={headers[k]?.readOnly}
                          // required={required}
                        >
                          {headers[k].options?.map((o, j) => {
                            return <option key={i + '_' + k + '_' + j} value={o.value}>{o.name}</option>
                          })}
                        </select> */}
                        <span className="tooltiptext">{error}</span>
                        {/* <Select
                          key={i + '_' + k}
                          data={headers[k].options!}
                          onChange={(t) => setProp?.(t.target.value, propPath!, i, k)}
                        /> */}
                      </td>
                    )
                  }

                  let defaultValue: string | number | undefined = dataRow[k]?.toString();

                  if(headerType === "number"){
                    inputType = "text"

                    if(decimals && decimals > 0) inputMode = "decimal"
                    else inputMode = "numeric"

                    if(defaultValue !== undefined){
                      let decs = ""
                      // we work with whole numbers. replace all dots
                      if(!decimals || decimals < 1){
                        defaultValue = defaultValue.replace(/[^0-9]/g, '');                        
                      }else{
                        defaultValue = defaultValue.replaceAll(",", '.');
                        defaultValue = defaultValue.replace(/[^0-9.]/g, '');
                        let firstDotApp = defaultValue.indexOf(".")
                        // Remove trailing dots. only 1 dot allowed.
                        if(firstDotApp > 0){                        
                          let wholeNumAndDot = defaultValue.substring(0, firstDotApp + 1)
                          decs = defaultValue.substring(firstDotApp + 1).replaceAll(".", "")

                          defaultValue = wholeNumAndDot + decs;
                        }else if(firstDotApp === 0){ // if dot is the first letter we add a 0 before it
                          defaultValue = "0" + defaultValue
                          firstDotApp = 1
                        }

                        if(isNaN(parseFloat(defaultValue))) defaultValue = undefined
                        else {
                          // dot is the last character, do not remove..
                          if(firstDotApp < defaultValue.length - 1)
                            defaultValue = parseFloat(parseFloat(defaultValue).toFixed(Math.min(decimals, decs.length)));                                                  
                        }
                      }
                      if (max && defaultValue && parseFloat(defaultValue.toString()) > max) defaultValue = max                     
                    }                    
                  }

                  return (
                    <td 
                      key={i + '_' + k}
                      className={ error ? "tooltip" : ""}
                    >
                      <input
                        key={i + '__' + k}
                        className={ error ? "error" : ""}
                        // onInvalid={(e: any) => { e.target.setCustomValidity(error); e.target.reportValidity();}}
                        value={defaultValue || ""}
                        type={inputType}
                        inputMode={inputMode}
                        min={min}
                        max={maxLength}
                        // required={required}
                        placeholder=" "
                        onChange={(e) => setProp?.(e.target.value, propPath!, i, k)}
                        onBlur={inputMode === "text" ? undefined : (e: React.FocusEvent<HTMLInputElement, Element>) => {
                          let val = parseFloat(e.currentTarget.value?.replace(/\.$/, ''));
                          setProp?.(val, propPath!, i, k)
                        }}
                        readOnly={headers[k]?.readOnly}
                      />
                      {headers[k]?.adornment &&
                        <p className="adornment">{headers[k].adornment}</p>
                      }
                      <span className="tooltiptext">{error}</span>
                    </td>
                  )
                })
              }
            </tr>
          })
        }
        
      </tbody>
    </table>
    {isAddable()}
    </div>
    
  }</>
}

export default InputTable