import { z } from "zod";
import { contactSchema } from "./Contact";

export const addressSchema = z.object({
  type: z.string().max(2).nullish(),
  addressId: z.string().nullish().readonly(),
  name: z.string().max(50),
  street1: z.string().max(35),
  street2: z.string().max(35).optional(),
  countryCode:z.string().max(2),
  city:z.string().max(50),
  zipCode:z.string().max(15),
  vatNo: z.string().max(20).optional(),
  contacts: z.array(contactSchema).min(0).optional()
});

export type AddressType = z.infer<typeof addressSchema>;