import { z } from "zod";
import { goodslineSchema } from "./Goodsline";
import { documentsSchema } from "./Document";
import { itemlineSchema } from "./Itemline";
import { shipment_NeededFieldsSchema } from "./Shipment_NeededFields";
import { addressSchema } from "./Address";


export const shipmentSchema = z.object({
    shipmentId: z.string().max(50).optional(),
    status: z.number().max(100),
    dispDate: z.string().transform((v) => `${v}T00:00:00.000Z`).pipe( z.string().datetime({ message: 'incorrect format' }) ),
    
    receiverPays: z.boolean().default(false),
    customerNumber: z.string().max(50).nullish().optional(),
    
    contactName: z.string().max(50).nullish().optional(),
    contactPhone: z.string().max(50).nullish().optional(),
    contactMobileNo: z.string().max(50).nullish().optional(),
    contactEmail: z.string().max(50).nullish().optional(),
    
    deliveryType: z.string().max(50),
    carrier: z.string().max(50).nullish().optional(),
    orderNo: z.string().max(50).nullish().optional(),
    // customerNumber: z.string().nullish().optional(),
    
    isBooked: z.boolean().nullish().optional(),
    TA_Status: z.enum(['10', '20', '80', '90']).optional(),
    trackingLink: z.string().nullish().optional(),
    incoterms: z.string().max(3).nullish().optional(),

    goodsLines: z.array(goodslineSchema).nullish().optional(),
    itemLines: z.array(itemlineSchema).nullish().optional(),
    ValidationError: z.string().nullish().optional(),
    noteInternal: z.string().nullish().optional(),
    checked: z.boolean().nullish().optional(),
    isInbound: z.boolean(),
    documents: z.array(documentsSchema).nullish().optional(),
    neededFields: z.array(shipment_NeededFieldsSchema).nullish().optional(),
    messageToCarrier: z.string().max(280).nullish().optional(),
    addresses: z.object({
        sender: addressSchema.optional().nullable(),
        receiver: addressSchema
    })

    

});

export type ShipmentType = z.infer<typeof shipmentSchema>;